export default [
  {
    key: 'country',
    labelKey: 'country',
    color: '#EB2F06',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'IGVSB, adaptado por Provita',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_2/layers/POLITICAL_LEVEL_1.zip',
    index: 1,
  },
  {
    key: 'biome',
    labelKey: 'biome',
    color: '#EAB455',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Provita',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_2/layers/BIOMES.zip',
    index: 2,
  },
  {
    key: 'state',
    labelKey: 'state',
    color: '#000000',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'IGVSB, adaptado por Provita',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_2/layers/POLITICAL_LEVEL_2.zip',
    index: 3,
  },
  {
    key: 'municipality',
    labelKey: 'municipality',
    color: '#ffff00',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'IGVSB, adaptado por Provita',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_2/layers/POLITICAL_LEVEL_3.zip',
    index: 4,
  },
  {
    key: 'watershed',
    labelKey: 'watershed',
    color: '#16DFF2',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Minec, 2014 y actualizado por Provita, 2024',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_2/layers/BASIN_LEVEL_1_PNRH.zip',
    index: 5,
  },
  {
    key: 'protected_areas',
    labelKey: 'protected_areas',
    color: '#1A7836',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'POA, 2015 y Provita, 2024: Provita, 2023, digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales; Planificación y Ordenación Ambiental (POA), 2015, Base de datos de las Área Bajo Régimen de Administración Especial (ABRAE)',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_2/layers/PROTECTED_AREA.zip',
    index: 6,
  },
  {
    key: 'indigenous_territorie',
    labelKey: 'indigenous_territorie',
    color: '#6A3D9A',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Wataniba y Provita, 2024: - Territorios demarcados: Secretaria Técnica de la Comisión Nacional de Demarcación del Hábitat y Tierra de los Pueblos y Comunidades Indígenas, Ministerio del Poder Popular para el Ambiente (2014), Mapa Tierras IndígenasDir. Gen. POT / Sec. Tec. Com. Nac.- Territorios autodemarcados: Trabajo de Wataniba con Organizaciones indígenas (2014, 2022, 2023).- Territorios tradicionales: Ministerio del Poder Popular para la Salud (mapa), 2007.',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_2/layers/INDIGENOUS_TERRITORIES.zip',
    index: 7,
  },
  {
    key: 'physiographic_regions',
    labelKey: 'physiographic_regions',
    color: '#C51B7D',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Provita, 2024',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_2/layers/PHYSIOGRAPHIC_REGIONS.zip',
    index: 8,
  },
];
