import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';

const API_URL =
  process.env.REACT_APP_GEE_API_URL ||
  'https://staging.api.mapbiomas.org/api/v1/amazonia';

const customSubmoduleProps = {
  coverage_rgb: {
    year: 'activeYear',
    territoryIds: ({ territoryId }) => [territoryId],
  },
};

export default function useGenericBaseLayer(params) {
  const { pathKey, layer } = params;

  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const customProps = customSubmoduleProps[pathKey] || {};
  const parsedCustomParams = _.reduce(
    customProps,
    (obj, paramFormatter, paramKey) => {
      if (_.isFunction(paramFormatter)) {
        obj[paramKey] = paramFormatter(params);
      } else if (_.isString(paramFormatter)) {
        obj[paramKey] = _.get(params, paramFormatter);
      } else {
        return obj;
      }
      return obj;
    },
    {}
  );

  let parsedParams = {
    initiative: 'chaco',
    ...parsedCustomParams,
  };

  if (pathKey === 'layer') {
    parsedParams['layer'] = layer;
  }

  const mapUrl = `${API_URL}/maps/${pathKey}?${qs.stringify(parsedParams, {
    arrayFormat: 'repeat',
  })}`;

  useEffect(() => {
    setLoading(true);
    fetch(mapUrl, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.text())
      .then((data) => {
        setUrl(data);
        setLoading(false);
      });
  }, [mapUrl]);

  return {
    loading,
    url,
  };
}
