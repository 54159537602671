export default {
  'default': {
    'territorial': [
      'country',
      'biome',
      'state',
      'municipality',
      'watershed',
    ],
    'land': [
      'protected_areas',
      'indigenous_territories',
      'physiographic_regions',
    ]
  },
  'simplified': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ]
  },
  'infrastructure': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ]
  },
  'fire': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ],
    'land': [
      'settlements',
      'quilombos',
      'indigenous_territories',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
      'municipality_protected_area_integral_protection',
      'municipality_protected_area_sustainable_use',
    ]
  },
  'soil': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
    ]
  },
  'sentinel': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
    ],
    'land': [
      'settlements',
      'quilombos',
      'indigenous_territories',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
    ]
  },
  'degradation': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ],
    'land': [
      'indigenous_territories',
      'municipality_protected_area_integral_protection',
      'municipality_protected_area_sustainable_use',
      'state_protected_area_integral_protection',
      'state_protected_area_sustainable_use',
      'federal_protected_area_integral_protection',
      'federal_protected_area_sustainable_use',
    ]
  },
};
